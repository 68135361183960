<template>
  <looker-report :url="url" />
</template>

<script>
export default {
  components: {
    LookerReport: () => import('@/modules/external_bi/components/LookerReport')
  },
  data: () => ({
    url: 'https://lookerstudio.google.com/embed/reporting/15029bea-67de-4c48-8c27-cc9741f8cd14/page/p_s4yrqbp8ld'
  })
}
</script>
